body.about-us p {
  margin-bottom: 1.5rem;
}

/*------------------------------
	= QUOTE SECTIONS
--------------------------------*/
.quote-section {
  text-align: center;
  p {
    text-transform: none;
    line-height: 1.5em;
    margin: 0.5rem auto 1.5rem;
  }
}
.quote-section .name {
  font-size: 1.15rem;
}

#about-opening-section {
  text-transform: uppercase;
}

/*------------------------------
	= FOUNDERS
--------------------------------*/
#about-founders {
  text-align: center;
  .profile {
    max-width: 17.1875rem;
    display: inline-block;
    float: none;
    margin: 0 0.5rem 3rem;
  }
}
#about-founders .profile img {
  width: 100%;
}
#about-founders .profile .name {
  margin: 1rem auto;
}
#about-founders .profile a {
  font-size: 2.5rem;
}

/*------------------------------
	= WRITEUP
--------------------------------*/
/*------------------------------
	= CTA SECTION
--------------------------------*/
#about-cta {
  text-align: center;
  text-transform: uppercase;
  span {
    display: block;
  }
}
@media only screen and (max-width: 43.75rem) {
  #about-cta span br {
    display: none;
  }
}

