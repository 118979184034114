/*------------------------------------------------*/
/*				= HOME =						  */
/*------------------------------------------------*/
body.home-page p {
  margin-bottom: 1.5rem;
}

/*------------------------------
	= OPENING SECTION
--------------------------------*/
#home-opening-section {
  text-align: center;
  #opening-heading {
    text-transform: uppercase;
    span {
      display: block;
      margin-top: 0.5rem;
    }
  }
  .report {
    max-width: 21.25rem;
    padding: 0 1%;
    margin-bottom: 1.5rem;
    h2 {
      color: #4db8ea;
      font-size: 1rem;
      -moz-transition: ease-in-out 0.3s;
      -o-transition: ease-in-out 0.3s;
      -webkit-transition: ease-in-out 0.3s;
      transition: ease-in-out 0.3s;
      text-transform: uppercase;
    }
  }
}
@media only screen and (min-width: 48rem) {
  #home-opening-section #opening-heading span {
    margin-top: 0;
  }
}
#home-opening-section .report .img-wrap {
  margin-bottom: 1.5rem;
  position: relative;
  overflow: hidden;
  -moz-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
  -webkit-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
}
#home-opening-section .report .img-wrap:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0;
  -moz-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
  -webkit-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
}
#home-opening-section .report .img-wrap img {
  width: 100%;
  height: auto;
  -moz-transition: ease-in-out 0.2s;
  -o-transition: ease-in-out 0.2s;
  -webkit-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
}
#home-opening-section .report a:hover .img-wrap {
  -moz-box-shadow: 0 3px 8px #adacac;
  -webkit-box-shadow: 0 3px 8px #adacac;
  box-shadow: 0 3px 8px #adacac;
}
#home-opening-section .report a:hover .img-wrap:after {
  opacity: 0;
}
#home-opening-section .report a:hover img {
  -moz-transform: scale(1.03);
  -ms-transform: scale(1.03);
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}
#home-opening-section .report a:hover h2 {
  color: #5bbd72;
}
@media (max-width: 47.9375rem) {
  #home-opening-section .report {
    float: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
  }
}

/*------------------------------
	= FREE TRIAL
--------------------------------*/
#home-free-trial {
  text-align: center;
  p {
    margin-bottom: 1.5rem;
  }
  span {
    display: block;
    text-transform: uppercase;
  }
}

#home-difference {
  text-align: center;
  .green-big {
    text-transform: uppercase;
  }
}

/*------------------------------
	= HIGHLIGHTS
--------------------------------*/
.highlight {
  margin-bottom: 2rem;
  /* ------ Tablets ------ */
}
.highlight .highlight-heading {
  margin-bottom: 0.75rem;
  h3 {
    font-weight: 700;
    text-transform: uppercase;
  }
}
@media only screen and (min-width: 48rem) {
  .highlight {
    text-align: left !important;
  }
  .highlight .highlight-heading {
    height: 3.75rem;
    position: relative;
    margin-bottom: 1rem;
  }
  .highlight .highlight-heading h3 {
    position: absolute;
    bottom: 0;
  }
}

/*------------------------------
	= FEATURES
--------------------------------*/
#home-features {
  .section-head {
    text-align: center;
    text-transform: uppercase;
  }
}
#home-features .feature:not(:last-of-type) {
  border-bottom: solid 1px #bdbdbd;
  margin-bottom: 2rem;
}
#home-features .feature .feature-top {
  /* ------ Tablets ------ */
}
#home-features .feature .feature-top .img-wrap {
  max-width: 40rem;
  margin-bottom: 3rem;
}
#home-features .feature .feature-top .img-wrap img {
  width: 100%;
  height: auto;
}
#home-features .feature .feature-top .img-wrap img:not(:first-of-type) {
  display: none;
}
#home-features .feature .feature-top .write-up {
  margin-bottom: 1rem;
  h3 {
    font-weight: 700;
  }
}
#home-features .feature .feature-top .dropdown {
  display: none;
}
@media only screen and (min-width: 48rem) {
  #home-features .feature .feature-top {
    margin-bottom: 2rem;
  }
  #home-features .feature .feature-top .write-up {
    padding-right: 3%;
    margin-bottom: 0;
  }
  #home-features .feature .feature-top .write-up p {
    margin-bottom: 1.5rem;
  }
  #home-features .feature .feature-top .dropdown {
    display: block;
  }
  #home-features .feature .feature-top .img-wrap {
    float: right !important;
    position: relative;
    height: 26.25rem;
    margin-bottom: 0;
    overflow: hidden;
  }
  #home-features .feature .feature-top .img-wrap img {
    position: absolute;
    top: 0;
    right: 0;
    display: block !important;
    opacity: 1;
    -moz-transition: ease-in-out 0.3s;
    -o-transition: ease-in-out 0.3s;
    -webkit-transition: ease-in-out 0.3s;
    transition: ease-in-out 0.3s;
  }
  #home-features .feature .feature-top .img-wrap img:not(.visible) {
    opacity: 0;
  }
  #home-features .feature .feature-top .dropdown ul {
    -moz-transition: box-shadow 0s ease-in-out 0s;
    -o-transition: box-shadow 0s ease-in-out 0s;
    -webkit-transition: box-shadow 0s ease-in-out;
    -webkit-transition-delay: 0s;
    transition: box-shadow 0s ease-in-out 0s;
    position: absolute;
    width: 100%;
    /* Hovered */
  }
  #home-features .feature .feature-top .dropdown ul li {
    position: relative;
    font-weight: 700;
    color: @gray;
    /* Top Level */
    /* Drop Down Items */
  }
  #home-features .feature .feature-top .dropdown ul li a {
    text-transform: uppercase;
    font-size: 0.875rem;
    color: #555555;
    display: inline-block;
    padding: 0.5rem;
  }
  #home-features .feature .feature-top .dropdown ul li:first-of-type {
    border: solid 1px #bdbdbd;
  }
  #home-features .feature .feature-top .dropdown ul li:first-of-type a:before {
    content: "";
    display: inline-block;
    font-family: "icons";
    color: #4db8ea;
    font-size: 1.5rem;
    line-height: 1em;
    vertical-align: middle;
    margin-top: -0.125rem;
    border-left: solid 1px #bdbdbd;
    padding-left: 0.25rem;
    margin-right: 0.5rem;
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -moz-transition: ease-in-out 0.2s;
    -o-transition: ease-in-out 0.2s;
    -webkit-transition: ease-in-out 0.2s;
    transition: ease-in-out 0.2s;
  }
  #home-features .feature .feature-top .dropdown ul li:not(:first-of-type) {
    opacity: 0;
    z-index: -1;
    -moz-transition: ease-in-out 0.3s;
    -o-transition: ease-in-out 0.3s;
    -webkit-transition: ease-in-out 0.3s;
    transition: ease-in-out 0.3s;
  }
  #home-features .feature .feature-top .dropdown ul li:not(:first-of-type) a {
    background-color: #fafafa;
    width: 100%;
    text-transform: none;
    padding-left: 2rem;
  }
  #home-features .feature .feature-top .dropdown ul li:not(:first-of-type) a:not(:last-of-type) {
    padding-bottom: 0;
  }
  #home-features .feature .feature-top .dropdown ul li:not(:first-of-type) a:hover {
    background-color: #e1e1e1;
  }
  #home-features .feature .feature-top .dropdown ul.active {
    -moz-box-shadow: 0 2px 2px lightgray;
    -webkit-box-shadow: 0 2px 2px lightgray;
    box-shadow: 0 2px 2px lightgray;
    -moz-transition: box-shadow 0.3s ease-in-out 0.3s;
    -o-transition: box-shadow 0.3s ease-in-out 0.3s;
    -webkit-transition: box-shadow 0.3s ease-in-out;
    -webkit-transition-delay: 0.3s;
    transition: box-shadow 0.3s ease-in-out 0.3s;
  }
  #home-features .feature .feature-top .dropdown ul.active li {
    opacity: 1;
    z-index: 1;
  }
  #home-features .feature .feature-top .dropdown ul.active li:nth-child(1) {
    -moz-transition-delay: 0.05s;
    -o-transition-delay: 0.05s;
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
  }
  #home-features .feature .feature-top .dropdown ul.active li:nth-child(2) {
    -moz-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }
  #home-features .feature .feature-top .dropdown ul.active li:nth-child(3) {
    -moz-transition-delay: 0.15s;
    -o-transition-delay: 0.15s;
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
  }
  #home-features .feature .feature-top .dropdown ul.active li:nth-child(4) {
    -moz-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  #home-features .feature .feature-top .dropdown ul.active li:nth-child(5) {
    -moz-transition-delay: 0.25s;
    -o-transition-delay: 0.25s;
    -webkit-transition-delay: 0.25s;
    transition-delay: 0.25s;
  }
  #home-features .feature .feature-top .dropdown ul.active li:nth-child(6) {
    -moz-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }
  #home-features .feature .feature-top .dropdown ul.active li:nth-child(7) {
    -moz-transition-delay: 0.35s;
    -o-transition-delay: 0.35s;
    -webkit-transition-delay: 0.35s;
    transition-delay: 0.35s;
  }
  #home-features .feature .feature-top .dropdown ul.active li:nth-child(8) {
    -moz-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
  }
  #home-features .feature .feature-top .dropdown ul.active li:nth-child(9) {
    -moz-transition-delay: 0.45s;
    -o-transition-delay: 0.45s;
    -webkit-transition-delay: 0.45s;
    transition-delay: 0.45s;
  }
  #home-features .feature .feature-top .dropdown ul.active li:nth-child(10) {
    -moz-transition-delay: 0.5s;
    -o-transition-delay: 0.5s;
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
  }
  #home-features .feature .feature-top .dropdown ul.active li a:before {
    color: #1686bb;
  }
  #home-features .feature .feature-top .dropdown ul.active li a:hover {
    cursor: pointer;
  }
}
#home-features .feature.right .feature-top {
  /* ------ Tablets ------ */
}
@media only screen and (min-width: 48rem) {
  #home-features .feature.right .feature-top .write-up {
    padding-right: 0;
    padding-left: 3%;
    text-align: right;
  }
  #home-features .feature.right .feature-top ul.img-dropdown {
    text-align: left;
  }
  #home-features .feature.right .feature-top .img-wrap {
    float: left !important;
  }
}

/*------------------------------
	= SEND YOUR REPORTS
--------------------------------*/
#home-send-reports {
  text-align: center;
  .green-big-bold-caps, .section-lead, .btn {
    text-transform: uppercase;
  }
}
#home-send-reports p.caps, #home-send-reports p.big-caps, #home-send-reports p.medium-caps, #home-send-reports p.small-bold-caps, #home-send-reports p.green-bold-caps, #home-send-reports p.green-big-bold-caps, #home-send-reports .page-section p.bottom-text, .page-section #home-send-reports p.bottom-text, #home-send-reports .bordered-section p.bottom-text, .bordered-section #home-send-reports p.bottom-text, #home-send-reports .bordered-arrow-section p.bottom-text, .bordered-arrow-section #home-send-reports p.bottom-text, #home-send-reports p.black-big, #home-send-reports p.green-big, #home-send-reports p.black-light, #home-send-reports .quote-section p, .quote-section #home-send-reports p, #home-send-reports #pricing-cta-section p:first-of-type, #pricing-cta-section #home-send-reports p:first-of-type, #home-send-reports p.green-light, #home-send-reports p.section-lead, #home-send-reports p.btn, #home-send-reports p.btn-green, #home-send-reports .pricing-table p.price, .pricing-table #home-send-reports p.price, #home-send-reports .site-footer p.footer-bottom, .site-footer #home-send-reports p.footer-bottom {
  margin: 1rem 0;
}

/*------------------------------
	= INTEGRATIONS

--------------------------------*/
#home-integrations {
  text-transform: uppercase;
  text-align: center;
}
/* = Logos
---------------------*/
ul.logos {
  /* ------ Mobile Landscape (hs-min) ------ */
}
ul.logos li {
  display: block;
  width: 100%;
  margin: 0 auto 2.5rem;
}
ul.logos li img {
  width: 100%;
}
ul.logos li.google-analytics {
  max-width: 10.625rem;
}
ul.logos li.google-search {
  max-width: 17.5rem;
}
ul.logos li.google-adwords {
  max-width: 10.75rem;
}
ul.logos li.moz {
  max-width: 7.125rem;
}
ul.logos li.bing {
  max-width: 8.8125rem;
}
ul.logos li.twitter {
  max-width: 4rem;
}
ul.logos li.facebook {
  max-width: 11.3125rem;
}
@media (min-width: 30rem) {
  ul.logos li {
    display: inline-block;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
}

/*------------------------------
	= GET STARTED
--------------------------------*/
#home-get-started {
  text-transform: uppercase;
  text-align: center;
}
#home-get-started .btn, #home-get-started .btn-green {
  margin-bottom: 1rem;
}

/*------------------------------
	= AS FEATURED
--------------------------------*/
#home-featured {
  text-transform: uppercase;
  text-align: center;
}
#home-featured ul.logos {
  /* ------ Mobile Landscape (hs-min) ------ */
}
#home-featured ul.logos li.financial-times {
  max-width: 3.5625rem;
}
#home-featured ul.logos li.website-mag {
  max-width: 10.8125rem;
}
#home-featured ul.logos li.startup-america {
  max-width: 11.875rem;
}
#home-featured ul.logos li.tech-columbus {
  max-width: 11.875rem;
}
#home-featured ul.logos li.pc-mag {
  max-width: 3.875rem;
}
#home-featured ul.logos li.duct-tape {
  max-width: 10.5rem;
}
#home-featured ul.logos li.best-affiliate {
  max-width: 14rem;
}
#home-featured ul.logos li.venture-beat {
  max-width: 12.875rem;
}
#home-featured ul.logos li.tnw {
  max-width: 10.25rem;
}
@media (min-width: 30rem) {
  #home-featured ul.logos li {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}
