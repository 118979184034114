.signup-form {
  border-radius: @border-radius-small;
  border: 1px solid lightgray;
  .close {
    position: relative;
    right: 10px;
    top: 6px;
    font-weight: 700;
  }
  .modal-header {
    background-color: lighten(@gray-lighter, 4%);
    .border-top-radius(@border-radius-small);
    border-bottom: 1px solid @brand-primary;
    .modal-title {
      font-size: floor(@font-size-large * 2);
      font-weight: 400;
      color: @brand-primary;
    }
    .modal-subtitle {
      color: darken(@gray-light, 10%);
      font-size: ceil(@font-size-base * 1.1);
      font-weight: 400;
      margin: 0;
    }
  }
  .modal-body {
    .form-group {
      label {
        color: @gray-light;
        font-size: @font-size-small;
        small {
          padding-left: 10px;
          color: lighten(@gray-light, 10%);
        }
      }
      .form-control {
        font-size: @font-size-large;
        border-radius: @border-radius-small;
        height: 42px;
        background-color: lighten(@gray-lighter, 4%);
        .box-shadow(none);
        &.form-error {
          border-color: @state-danger-text;
          .box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work
          &:focus {
            border-color: darken(@state-danger-text, 10%);
            @shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten(@state-danger-text, 20%);
            .box-shadow(@shadow);
          }
        }
      }
      .text-danger {
        padding-top: 3px;
        font-size: @font-size-small;
      }
      &.half {
        width: 50%;
        display: inline-block;
        &.first {
          padding-right: 10px;
        }
        &.last {
          padding-left: 10px;
        }
      }
    }
    .fine-print {
      padding-top: 10px;
      text-align: center;
      font-size: @font-size-small;
      p {
        &:first-child {
          margin-bottom: 5px;
        }
        a {
          font-weight: 700;
        }
      }
    }
  }
  .modal-footer {
    background-color: lighten(@gray-lighter, 4%);
    .border-bottom-radius(@border-radius-small);
    .btn {
      font-weight: 700;
      font-size: ceil(@font-size-base * 1.1);
      text-transform: uppercase;
    }
    .cancel {
      float: left;
      padding: 16px 0;
      color: @gray-light;
      background-color: inherit;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
    .create {
      padding: 16px 48px;
    }
  }
}

body#signup {
  .signup-form {
    .box-shadow(none);
    margin-bottom: 20px;
    .close {
      display: none;
    }
    .modal-footer {
      .cancel {
        display: none;
      }
    }
  }
}
