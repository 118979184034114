/*------------------------------------------------*/
/*			= PRIVACY & TERMS =					  */
/*------------------------------------------------*/
body.privacy-policy #content,
body.terms #content {
  max-width: 56.25rem;
}
body.privacy-policy header,
body.terms header {
  padding-bottom: 0;
}
body.privacy-policy h2,
body.terms h2 {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 700;
}
body.privacy-policy p,
body.terms p {
  font-size: 0.9375rem;
}
