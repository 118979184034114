/*------------------------------
	= OPENING SECTION
--------------------------------*/
#agencies-opening-section {
  text-align: center;
  text-transform: uppercase;
}
#agencies-opening-section p br {
  display: none;
  /* ------ Medium Screens ------ */
}
@media (min-width: 62rem) {
  #agencies-opening-section p br {
    display: block;
  }
}
#agencies-opening-section .equation {
  margin-top: 2rem;
  /* Operators */
  /* ------ Tablets ------ */
  /* ------ Desktops ------ */
}
#agencies-opening-section .equation span {
  display: block;
  position: relative;
  font-size: 0.875rem;
  line-height: 1.3em;
  vertical-align: middle;
  /* Factors */
  /* Product */
  /* Content */
}
#agencies-opening-section .equation span:before {
  position: absolute;
  bottom: 0;
  color: #bdbdbd;
}
#agencies-opening-section .equation span input {
  height: 2.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: block;
  margin-bottom: 0.35rem;
}
#agencies-opening-section .equation span.factor {
  width: 9rem;
}
#agencies-opening-section .equation span.factor input {
  width: 80%;
  background-color: #fafafa;
  line-height: 2.5rem;
  font-style: normal;
  font-size: 1.5rem;
  border: solid 2px #bdbdbd;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
#agencies-opening-section .equation span.product {
  max-width: 12.5rem;
}
#agencies-opening-section .equation span.product input {
  text-align: center;
  width: 100%;
  font-size: 1.75rem;
  line-height: 2.375rem;
  color: #da291f;
  font-style: normal;
  border: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
#agencies-opening-section .equation span span {
  font-size: 0.875rem;
  line-height: 1.3em;
  position: absolute;
  top: 3rem;
}
#agencies-opening-section .equation > i {
  color: #bdbdbd;
  display: inline-block;
  font-size: 1.6rem;
  margin: 1rem auto 0.95rem;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
#agencies-opening-section .equation > i.icon-equals {
  width: 8.75rem;
  height: 0.25rem;
  background-color: #bdbdbd;
  overflow: hidden;
  margin: 1rem auto 1.25rem;
}
@media only screen and (min-width: 48rem) {
  #agencies-opening-section .equation {
    margin-top: 4rem;
  }
  #agencies-opening-section .equation span {
    display: inline-block;
    vertical-align: top;
  }
  #agencies-opening-section .equation span input {
    margin-bottom: 1.5rem;
  }
  #agencies-opening-section .equation span.product input {
    border-bottom: solid 2px #bdbdbd;
  }
  #agencies-opening-section .equation > i {
    margin: 0rem -1% !important;
  }
  #agencies-opening-section .equation > i.icon-equals {
    background-color: transparent;
    height: auto;
    margin-right: 1% !important;
    width: auto;
    padding-top: 0.5rem;
  }
}
@media (min-width: 62rem) {
  #agencies-opening-section .equation > i,
  #agencies-opening-section .equation > i.icon-equals {
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
}

/*------------------------------
	= HIGHLIGHTS
--------------------------------*/
#agencies-highlights {
  /* ------ Tablets ------ */
  text-align: center;
  text-transform: uppercase;
  p {
    text-transform: none;
  }
}
#agencies-highlights #highlights {
  margin-top: 3.5rem;
}
#agencies-highlights h3 {
  margin-bottom: 1rem;
}
@media only screen and (min-width: 48rem) {
  #agencies-highlights .highlight {
    padding-left: 5%;
    padding-right: 5%;
  }
}

#agencies-quote {
  text-align: center;
}

#agencies-cta {
  text-align: center;
  text-transform: uppercase;
}
