#site-navigation {
  float: right;
  line-height: 3em;
  text-transform: uppercase;
  /* Top Level */
  /* Hide on Mobile */
  & > ul {
    & > li {
      a, span {
        margin-left: 1.5rem;
        color: #555;
        font-weight: 700;
      }
      a.active {
        color: #a2a2a2;
      }
      a.btn {
        color: #fff;
        &:hover, &:active {
          color: #fff;
        }
      }
    }
  }
}
/*#site-navigation > ul > li a:not(.cta), #site-navigation > ul > li span:not(.cta) {
  color: #555555;
  line-height: 3.25em;
}*/
#site-navigation > ul > li a:not(.btn):not(span):hover, #site-navigation > ul > li span:not(.btn):not(span):hover {
  color: #a2a2a2;
}
#site-navigation > ul > li .sub-nav {
  display: block;
  position: absolute;
  opacity: 0;
  z-index: -1;
  background-color: #fafafa;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 3rem;
  border-top: solid 4px #4db8ea;
  -moz-box-shadow: 0 3px 6px lightgray;
  -webkit-box-shadow: 0 3px 6px lightgray;
  box-shadow: 0 3px 6px lightgray;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
#site-navigation > ul > li .sub-nav li {
  display: block;
}
#site-navigation > ul > li .sub-nav li a {
  color: #555555;
  text-transform: none;
  margin-left: 0;
  line-height: 3.25rem;
  font-weight: 400;
}
#site-navigation > ul > .has-sub {
  cursor: default;
}
#site-navigation > ul > li.has-sub:hover .sub-nav {
  opacity: 1;
  z-index: 2;
}
@media (max-width: 52.5rem) {
  #site-navigation {
    display: none;
  }
}

/*------------------------------
	= MOBILE NAV
--------------------------------*/
/* = BUTTON
---------------------*/
.mobile-nav-trigger {
  width: 1.875rem;
  height: 1.875rem;
  position: absolute;
  right: 1rem;
  top: 2rem;
  margin-top: 1%;
  display: none;
  /* Opened */
  /* ------ Medium Screens ------ */
}
@media (max-width: 52.5rem) {
  .mobile-nav-trigger {
    display: block;
  }
}
.mobile-nav-trigger .container {
  width: 100%;
  height: 100%;
  position: relative;
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -moz-transition: ease-in-out 0.5s;
  -o-transition: ease-in-out 0.5s;
  -webkit-transition: ease-in-out 0.5s;
  transition: ease-in-out 0.5s;
}
.mobile-nav-trigger .container span {
  display: block;
  position: absolute;
  height: 0.3125rem;
  width: 100%;
  background-color: #4db8ea;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -moz-transition: ease-in-out 0.25s;
  -o-transition: ease-in-out 0.25s;
  -webkit-transition: ease-in-out 0.25s;
  transition: ease-in-out 0.25s;
}
.mobile-nav-trigger .container span:nth-child(1) {
  top: 0;
}
.mobile-nav-trigger .container span:nth-child(2) {
  top: 1.125rem;
  top: 33%;
}
.mobile-nav-trigger .container span:nth-child(3) {
  top: 2.25rem;
  top: 66%;
}
.mobile-nav-trigger.opened span:nth-child(1) {
  top: 1.125rem;
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.mobile-nav-trigger.opened span:nth-child(2) {
  opacity: 0;
  left: -3.75rem;
}
.mobile-nav-trigger.opened span:nth-child(3) {
  top: 1.125rem;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

/* = OFF CANVAS MENU
---------------------*/
#mobile-navigation {
  background-color: #fafafa;
  border-left: solid 1px #bdbdbd;
  .mm-panel {
    top: 3.75rem;
  }
}
#mobile-navigation .mm-navbar-top {
  padding: 0 0.625rem;
  height: 3rem;
}
#mobile-navigation .mm-prev {
  padding-top: 1rem;
}
#mobile-navigation .mm-prev {
  top: 0.4rem;
}
#mobile-navigation .mm-title {
  width: 10rem;
  font-family: "montserratregular", Arial, sans-serif;
  margin: 0.35rem 0 0 0.4rem;
}
#mobile-navigation .mm-title img {
  margin-top: -0.35rem;
}
#mobile-navigation .mm-next {
  width: 100%;
}
#mobile-navigation .mm-next:before {
  display: none;
}
#mobile-navigation .mm-close {
  top: 0.25rem;
  color: #555555;
  font-weight: bold;
}
#mobile-navigation li:after {
  display: none;
}
#mobile-navigation li a:not(.mm-next):after {
  display: none;
}
#mobile-navigation .cta {
  .btn-primary();
  width: 8.75rem;
  color: white;
  padding: 0.75rem;
  margin-top: 1rem;
  margin-left: 1rem;
}
#mobile-navigation ul:not(.sub-nav) span,
#mobile-navigation ul:not(.sub-nav) a:not(.mm-next):not(.cta) {
  -moz-transition: ease-in-out 0.2s;
  -o-transition: ease-in-out 0.2s;
  -webkit-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
  text-transform: uppercase;
}
#mobile-navigation ul:not(.sub-nav) span:hover,
#mobile-navigation ul:not(.sub-nav) a:not(.mm-next):not(.cta):hover {
  color: #bdbdbd;
}
#mobile-navigation ul:not(.sub-nav) .mm-next:hover ~ span {
  color: #bdbdbd;
}


