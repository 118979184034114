// whitelist specific bootstrap modules instead of including all from bootstrap.less
// Core variables and mixins
@import "variables.less";
@import "mixins.less";
// Reset and dependencies
@import "normalize.less";
//@import "print.less";
//@import "glyphicons.less";
// Core CSS
@import "scaffolding.less";
@import "type.less";
@import "code.less";
@import "grid.less";
@import "tables.less";
@import "forms.less";
@import "buttons.less";
// Components
@import "component-animations.less";
@import "dropdowns.less";
@import "button-groups.less";
@import "input-groups.less";
@import "navs.less";
//@import "navbar.less";
@import "breadcrumbs.less";
@import "pagination.less";
@import "pager.less";
@import "labels.less";
@import "badges.less";
//@import "jumbotron.less";
@import "thumbnails.less";
@import "alerts.less";
@import "progress-bars.less";
@import "media.less";
@import "list-group.less";
@import "panels.less";
@import "responsive-embed.less";
@import "wells.less";
@import "close.less";
// Components w/ JavaScript
@import "modals.less";
@import "tooltip.less";
@import "popovers.less";
//@import "carousel.less";
// Utility classes
@import "utilities.less";
@import "responsive-utilities.less";

@brand-primary: #4EB8EA;
@brand-success: #50B667;

@modal-title-padding: 30px;
@modal-inner-padding: 30px;

@font-family-sans-serif: proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif;

@import (inline) "bower_components/mmenu/dist/css/jquery.mmenu.all.css";

@import (inline) "bower_components/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.min.css";

html {
  font-size: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.clearfix, #masthead, #go-big ul li {
  clear: both;
}

.top-align {
  vertical-align: top;
}

.hide {
  opacity: 0;
}

.ontop {
  z-index: 9999;
}

.transparent {
  background: transparent !important;
}

.hidden {
  opacity: 0;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.full-img {
  width: 100%;
  height: 100%;
}

.full-bg {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

/* Vertically Center Child Elements */
.vertical-container {
  height: 100%;
}
.vertical-container:before {
  display: inline-block;
  content: '';
  height: 100%;
  vertical-align: middle;
}
.vertical-container > * {
  display: inline-block;
  vertical-align: middle;
  float: none;
  max-width: 98%;
}

/* Center Element */
.centered, .small-wrap, .page-section .bottom-text, .bordered-section .bottom-text, .bordered-arrow-section .bottom-text, .bordered-arrow-section:before, #custom-report-content #showcase-wrap .showcase, #pricing-tables, #agencies-opening-section .equation span, #agencies-opening-section .equation span input, #agencies-opening-section .equation span.factor input, #agencies-opening-section .equation span span, .site-footer img.logo {
  left: 0;
  right: 0;
  margin-left: auto !important;
  margin-right: auto !important;
}

/* Icons */
.icons {
  font-family: 'hillicons';
}

/*------------------------------
    = ICONS
--------------------------------*/
@font-face {
  font-family: 'icons';
  src: url("../fonts/icons/icons.eot?a0ajbt");
  src: url("../fonts/icons/icons.eot?#iefixa0ajbt") format("embedded-opentype"), url("../fonts/icons/icons.ttf?a0ajbt") format("truetype"), url("../fonts/icons/icons.woff?a0ajbt") format("woff"), url("../fonts/icons/icons.svg?a0ajbt#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"], [class*=" icon-"] {
  font-family: 'icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-equals:before {
  content: "\e603";
}

.icon-caret:before {
  content: "\e600";
}

.icon-cross:before {
  content: "\e602";
}

.icon-linkedin:before {
  content: "\e601";
}
/* Circles */
.circle, .client img, .bordered-arrow-section:before, #custom-report-content #showcase-wrap .showcase-nav button:after {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

/* Rounded Elements */
.rounded, .btn, .btn-green, .pricing-table {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

/* Clean List */
.clean-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.clean-list li {
  margin: 0;
  padding: 0;
}

ul.inline li {
  display: inline-block;
}

/* Height = Width */
.equal-height {
  position: relative;
}
.equal-height .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.equal-height:before {
  content: '';
  display: block;
  padding-top: 100%;
}

/* Section Padding */
.section-padding {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.small-font, #reports-nav li a, #next-report-nav a {
  font-size: 0.8125rem !important;
}

.large-font, .client span, #custom-report-content #testimonial .quote {
  font-size: 1.125rem !important;
}

.small-bold-caps {
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
}

.big-caps {
  font-size: 1.3125rem;
  text-transform: uppercase;
}

.medium-caps {
  font-size: 1.125rem;
  text-transform: uppercase;
}

/* = COLORS
---------------------*/
.black, .black-light, .quote-section p, #pricing-cta-section p:first-of-type, .black-big, .black-medium {
  color: #555555;
}

.lightgreen {
  color: #5bbd72;
}

.red-alert {
  color: #da291f;
  position: relative;
  font-style: normal;
  display: inline-block;
}
.red-alert:after {
  content: '';
  width: 100%;
  position: absolute;
  border: solid 1px #da291f;
  bottom: 0.1em;
  left: 0;
}

body,
p,
ul,
ol,
dl {
  font-size: 1rem;
  color: #555555;
  line-height: 1.7em;
}

p {
  margin-bottom: 1.5em;
}

i {
  font-style: normal;
}

/*------------------------------
	= HEADINGS
--------------------------------*/
.black-big, .green-big, .black-light, .quote-section p, #pricing-cta-section p:first-of-type, .green-light {
  font-size: 1.3125rem;
  line-height: 1.2em;
}

.black-big, .green-big {
  font-size: 1.4375rem;
  /* ------ Tablets ------ */
}
@media only screen and (min-width: 48rem) {
  .black-big, .green-big {
    font-size: 2.25rem;
  }
}

/* = BLACK HEADINGS
---------------------*/
.black-medium {
  font-size: 1.1875rem;
}

/* = GREEN HEADINGS
---------------------*/
.green-light, .green-big {
  color: #5bbd72;
}

.green-medium {
  color: #5bbd72;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.green-bold-caps {
  font-weight: 700;
}

.green-bold-caps, .green-big-bold-caps, .quote-section .name, #as-you-go h3, #go-big h3, body.privacy-policy h2,
body.terms h2 {
  color: #5bbd72;
  font-size: 1.4375rem;
  line-height: 1.4em;
}

.green-big-bold-caps {
  font-size: 1.4375rem;
  text-transform: uppercase;
  font-weight: 700;
  /* ------ Tablets ------ */
}
@media only screen and (min-width: 48rem) {
  .green-big-bold-caps {
    font-size: 2.25rem;
  }
}

/* = GENERAL
---------------------*/
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
  margin: 0;
  line-height: 1.2em;
}

.section-lead {
  margin: 1rem 0;
}

/*------------------------------
	= LINKS
--------------------------------*/
a {
  color: #4db8ea;
  -moz-transition: ease-in-out 0.2s;
  -o-transition: ease-in-out 0.2s;
  -webkit-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
}
a:hover {
  color: #1686bb;
  cursor: pointer !important;
  text-decoration: none;
}

/*------------------------------
	= BUTTONS
--------------------------------*/
.btn, .btn-green {
  text-transform: uppercase;
  display: inline-block;
  background-color: #4db8ea;
  color: white;
  font-weight: 700;
  line-height: 1.2em;
  padding: 1rem 1.5rem;
  border: none;
}
.btn, .btn-green {
  &:hover, &:active {
    background-color: #1686bb;
    color: white;
  }
  &:focus {
    color: white;
  }
}

.btn-green {
  background-color: #5bbd72;
  &:hover, &:active {
    background-color: #337f45;
  }
}

button:focus {
  outline: none;
}

.container-fluid {
  max-width: 62.5rem;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.small-wrap {
  max-width: 40.625rem;
}

.framed, .client img {
  padding: 0.25rem;
  border: solid 1px #bdbdbd;
}

.client {
  margin-bottom: 1.5rem;
}
.client img {
  width: 5.9375rem;
}
.client span {
  color: #5bbd72;
  display: inline-block;
  margin-left: 1rem;
}
.client span:before {
  content: '-';
}

#top-nav {
  background-color: #555555;
  .links {
    .center-block();
    max-width: 61rem;
    text-align: right;
    .btn {
      color: darken(@gray-lighter, 10%);
      font-size: @font-size-small;
      background-color: inherit;
      padding: 3px 6px;
      &.active {
      color: lighten(@gray-lighter, 10%);
      }
    }
  }
}

/*----------------------------------------------*/
/*			= SECTIONS	=						*/
/*----------------------------------------------*/
/*------------------------------
	= PAGE SECTIONS
--------------------------------*/
.page-section, .bordered-section, .bordered-arrow-section {
  position: relative;
  padding: 2.5rem 0;
}
.page-section .bottom-text, .bordered-section .bottom-text, .bordered-arrow-section .bottom-text {
  color: #bdbdbd;
  position: absolute;
  bottom: -1.4rem;
  z-index: 1;
  /* ------ Small Screen Only ------ */
}
.page-section .bottom-text span, .bordered-section .bottom-text span, .bordered-arrow-section .bottom-text span {
  background-color: white;
  display: inline-block;
  padding: 0.5rem;
}
@media (max-width: 47.9375rem) {
  .page-section .bottom-text, .bordered-section .bottom-text, .bordered-arrow-section .bottom-text {
    display: none;
  }
}

.bordered-section, .bordered-arrow-section {
  border-top: solid 1px #bdbdbd;
}

.bordered-arrow-section:before {
  font-family: 'icons';
  content: "";
  width: 1.8125rem;
  height: 1.8125rem;
  background-color: #fafafa;
  color: #bdbdbd;
  font-size: 1.5rem;
  text-indent: 0.075rem;
  line-height: 1.5rem;
  position: absolute;
  top: -0.875rem;
  left: -0.5rem;
  border: solid 2px #bdbdbd;
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.section-head {
  margin-bottom: 2rem;
}

/* = CTA SECTIONS
---------------------*/
.cta-section {
  /* ------ Tablets ------ */
}
.cta-section .section-heading {
  margin-bottom: 1.5rem;
}
.cta-section .section-heading p {
  margin-bottom: 0;
  margin-top: 1.25rem;
}
.cta-section .btn, .cta-section .btn-green {
  margin-bottom: 1.5rem;
}
.cta-section .section-lead {
  margin: 0;
}
@media only screen and (min-width: 48rem) {
  .cta-section .btn, .cta-section .btn-green {
    font-size: 1.125rem;
    padding: 1.25em;
  }
}

#masthead {
  padding: 1.75rem 0 1.5rem;
  border-bottom: solid 1px #bdbdbd;
}
#masthead > div {
  display: inline-block;
  vertical-align: middle;
}

#head-logo {
  width: 60%;
  max-width: 14rem;
}

@import "include/marketing/nav";
@import "include/marketing/home";
@import "include/marketing/signup";
@import "include/marketing/agencies";
@import "include/marketing/custom-reports";
@import "include/marketing/about";
@import "include/marketing/pricing";
@import "include/marketing/privacy-policy";

/* Footer */
/*================================================================================
*	FOOTER.scss
*	- Footer Stuff
----------------------------------------------------------------------------------

=================================================================================*/
.site-footer {
  text-align: center;
}
.site-footer * {
  line-height: 1.4em;
}
.site-footer img.logo {
  width: 80%;
  max-width: 17.5rem;
  .center-block();
}
.site-footer nav {
  margin: 1.5rem;
}
.site-footer nav li {
  display: block;
  margin-bottom: 0.25rem;
}
.site-footer nav li a {
  margin: 0 0.75rem;
  display: inline-block;
  text-transform: uppercase;
}
.site-footer nav li.heading {
  font-size: 0.875rem;
  display: inline-block;
  padding: 0.25rem 0 0.15rem;
}
.site-footer nav br {
  display: none;
  /* ------ Tablets ------ */
}
@media only screen and (min-width: 48rem) {
  .site-footer nav br {
    display: block;
  }
}
.site-footer .footer-bottom {
  font-size: 0.875rem;
}
.site-footer .footer-bottom span:last-child {
  display: block;
}
@media only screen and (min-width: 48rem) {
  .site-footer nav li {
    display: inline-block;
  }
  .site-footer nav br {
    display: block;
  }
}
