#reports-nav {
  text-align: center;
  border-bottom: solid 1px #bdbdbd;
  li {
    padding: 0.5rem 1.5rem;
    a {
      font-size: 0.8125rem;
      text-transform: uppercase;
      font-weight: 700;
    }
    &.current {
      a {
        color: #5bbd72;
        &:hover {
          cursor: default;
        }
      }
    }
  }
}

@media (max-width: 47.9375rem) {
  #reports-nav {
    display: none;
  }
}

/*------------------------------
	= HEADER
--------------------------------*/
#custom-report-header {
  text-align: center;
  text-transform: uppercase;
  span {
    display: block;
  }
}

/*------------------------------
	= CONTENT
--------------------------------*/
#custom-report-content {
  /* = TESTIMONIAL
  ---------------------*/
  /* = SHOWCASE
  ---------------------*/
}
#custom-report-content #testimonial {
  /* ------ Tablets ------ */
}
#custom-report-content #testimonial .quote {
  margin-bottom: 1.5rem;
}
#custom-report-content #testimonial .client {
  margin-bottom: 1.5rem;
}
@media only screen and (min-width: 48rem) {
  #custom-report-content #testimonial {
    padding-top: 1rem;
    padding-right: 4%;
  }
}
#custom-report-content .client-writeup p {
  font-size: 0.875rem;
}
#custom-report-content #showcase-wrap {
  margin-bottom: 1.5rem;
  position: relative;
  /* ------ Tablets ------ */
  /* Scrollbar */
  /* Nav */
}
@media only screen and (min-width: 48rem) {
  #custom-report-content #showcase-wrap:before {
    content: '';
    background-image: url(../img/marketing/scroll.svg);
    background-size: 100% 100%;
    width: 4.375rem;
    height: 4.375rem;
    position: absolute;
    top: -4.5625rem;
    right: 0;
  }
}
#custom-report-content #showcase-wrap .showcase {
  max-width: 38.4375rem;
  height: 0;
  padding-top: 88%;
  position: relative;
}
#custom-report-content #showcase-wrap .showcase .showcase-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
}
#custom-report-content #showcase-wrap .showcase .showcase-inner img {
  width: 100%;
}
#custom-report-content #showcase-wrap .showcase .mCustomScrollBox {
  border: solid 1px #bdbdbd;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
#custom-report-content #showcase-wrap .mCSB_scrollTools:hover {
  opacity: 1;
  cursor: pointer;
}
#custom-report-content #showcase-wrap .mCSB_buttonUp,
#custom-report-content #showcase-wrap .mCSB_buttonDown {
  width: 1.5625rem;
  height: 1.5625rem;
  opacity: 1;
  background-image: none;
  -webkit-filter: none;
  filter: none;
  font-family: 'icons';
}
#custom-report-content #showcase-wrap .mCSB_buttonUp:before,
#custom-report-content #showcase-wrap .mCSB_buttonDown:before {
  content: "";
  width: 100%;
  height: 100%;
  font-size: 2rem;
  font-weight: bold;
  display: block;
  -moz-transition: ease-in-out 0.2s;
  -o-transition: ease-in-out 0.2s;
  -webkit-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
}
#custom-report-content #showcase-wrap .mCSB_buttonUp:before {
  margin-top: -0.25rem;
  margin-left: -0.44rem;
}
#custom-report-content #showcase-wrap .mCSB_buttonDown:before {
  margin-top: 0.25rem;
  margin-left: -0.05rem;
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
#custom-report-content #showcase-wrap .showcase-nav {
  display: none;
  margin-top: 1rem;
}
#custom-report-content #showcase-wrap .showcase-nav span {
  width: 3.5rem;
  display: inline-block;
}
#custom-report-content #showcase-wrap .showcase-nav button {
  width: 2.8125rem;
  height: 2.8125rem;
  border: none;
  padding: 0;
  background-color: white;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
}
#custom-report-content #showcase-wrap .showcase-nav button:after {
  content: "";
  width: 100%;
  height: 100%;
  line-height: 100%;
  color: #4db8ea;
  font-size: 2.5rem;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  border: solid 1px #bdbdbd;
}
#custom-report-content #showcase-wrap .showcase-nav button.scroll-down:after {
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/*------------------------------
	= CTA
--------------------------------*/
#custom-reports-cta {
  text-align: center;
  text-transform: uppercase;
  a {
    font-weight: 700;
  }
}
@media (max-width: 47.9375rem) {
  #custom-reports-cta .section-heading br {
    display: none;
  }
}

/*------------------------------
	= BOTTOM NAV
--------------------------------*/
#next-report-nav {
  border-top: solid 1px #bdbdbd;
  padding: 0.75rem 0;
  padding-right: 1rem;
  text-align: right;
}
#next-report-nav a {
  line-height: 1.2em;
  display: inline-block;
  max-width: 12.5rem;
  text-transform: uppercase;
  font-weight: 700;
}
#next-report-nav a:after {
  content: "";
  font-family: "icons";
  display: inline-block;
  vertical-align: middle;
  font-size: 1.25rem;
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
@media (min-width: 30rem) {
  #next-report-nav a {
    max-width: none;
  }
}
