/*------------------------------------------------*/
/*				= PRICING =						  */
/*------------------------------------------------*/
/*------------------------------
	= OPENING SECTION
--------------------------------*/
.msg {
  font-size: 0.8125rem;
  line-height: 1.3em;
  display: block;
  margin: 1rem auto;
  font-weight: 700;
}

/* = TABLES
---------------------*/
#pricing-tables {
  max-width: 26.25rem;
  padding-left: 0;
}

.pricing-table {
  margin-bottom: 1.5rem;
  border: solid 1px lightgray;
}
.pricing-table * {
  font-size: 0.875rem;
}
.pricing-table {
  h2 {
    font-size: 1.75rem;
    color: white;
    text-align: center;
    text-transform: uppercase;
  }
  h3 {
    text-transform: uppercase;
    font-weight: 700;
  }
}
.pricing-table h2,
.pricing-table .body {
  padding: 1rem 1rem;
  strong {
    text-transform: uppercase;
  }
}
.pricing-table .price {
  font-size: 0.875rem;
  display: block;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: solid 1px lightgray;
  text-align: center;
  text-transform: uppercase;
}
.pricing-table .price i {
  font-style: normal;
  font-size: 1.875rem;
  vertical-align: middle;
  font-weight: 700;
}
.pricing-table ul {
  padding-left: 1rem;
}
.pricing-table ul li {
  margin-bottom: 0.25rem;
}

/* FIRST TABLE */
#as-you-go h2 {
  background-color: #5bbd72;
}
#as-you-go .price i,
#as-you-go ul li {
  color: #5bbd72;
}
#as-you-go ul li span {
  color: #555555;
}
#as-you-go h3 {
  font-size: 1rem;
  margin-bottom: 0.25rem;
}

/* SECOND TABLE */
#go-big {
  /* ------ Mobile Landscape (hs-min) ------ */
  text-align: center;
}
#go-big h2 {
  background-color: #4db8ea;
}
#go-big i {
  color: #4db8ea;
  font-weight: 700;
}
#go-big h3 {
  color: #4db8ea;
  line-height: 1.1em;
  margin-bottom: 0.25rem;
}
#go-big h3 .pre {
  display: block;
  color: #555555;
}
#go-big ul li {
  .clearfix();
  .clients {
    display: inline-block;
    vertical-align: top;
  }
  .rate {
    display: block;
    margin-bottom: 0.5rem;
    i {
      font-style: normal;
    }
  }
}
@media (min-width: 30rem) {
  #go-big ul li .clients,
  #go-big ul li .rate {
    background-color: white;
    z-index: 2;
    position: relative;
  }
  #go-big ul li .clients {
    float: left;
  }
  #go-big ul li .clients:after {
    content: '';
    width: 220%;
    height: 0.0625rem;
    position: absolute;
    bottom: 0.375rem;
    margin-left: 0.25rem;
    z-index: 1;
    border-bottom: dotted 2px #bdbdbd;
  }
  #go-big ul li .rate {
    display: inline-block;
    vertical-align: top;
    float: right;
    padding-left: 0.25rem;
  }
}

/*------------------------------
	= CTA SECTION
--------------------------------*/
#pricing-cta-section {
  .green-big-bold-caps {
    text-align: center;
  }
  p:first-of-type {
    margin: 1.5rem 0;
    text-transform: uppercase;
  }
}
@media only screen and (max-width: 34.375rem) {
  #pricing-cta-section p:first-of-type {
    font-size: 1rem;
    text-transform: none !important;
    font-style: italic;
  }
  #pricing-cta-section p:first-of-type .bottom {
    font-style: normal;
    margin-top: 1rem;
    font-family: "montserratregular", Arial, sans-serif;
    display: block;
  }
}
#pricing-cta-section a[class*=btn] {
  margin: 1.5rem 0.5rem 0.5rem;
  text-transform: uppercase;
}
.buttons {
  text-align: center;
}
